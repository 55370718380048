import Common from "./common.js"

class Form extends Common{
  constructor() {
    super();
    var CI = this;
    this.token = ''
    this.validate("#dealform")
    this.getFormDetails('#dealform')
    this.showToolTip()
    this.fillform()
    this.popupPartners()
    this.toggleIcon()
    this.showTab(this.currentTab);
    var chk1 = $("input[type='checkbox'][name='agree']");
    var chk2 = $("input[type='checkbox'][name='partners-tp']");

    chk1.on('change', function(){
     chk2.prop('checked',this.checked);
    });

    document.addEventListener("DOMContentLoaded", function () {
      const questionElements = document.querySelectorAll("[data-action='click->form#toggleAnswer']");
      questionElements.forEach(function (questionElement) {
        questionElement.addEventListener("click", function () {
          const answerId = this.getAttribute("data-id");
          const answerElement = document.getElementById("answer" + answerId);
          const crossIconElements = document.querySelectorAll("[data-target='form.crossIcon'][data-id='" + answerId + "']");
          answerElement.classList.toggle("answer-visible");
          crossIconElements.forEach(function (crossIconElement) {
            crossIconElement.classList.toggle("tw-opacity-0");
          });
        });
      });
    });

    const minusButton = document.getElementById('minus');
    const plusButton = document.getElementById('plus');
    const inputField = document.getElementById('total-bedrooms');
    if (minusButton) {
      minusButton.addEventListener('click', event => {
        event.preventDefault();
        let currentValue = Number(inputField.innerText) || 0;
        if (currentValue > 1) {
          currentValue = currentValue-1;
          inputField.innerText = currentValue;
        }
      });

      plusButton.addEventListener('click', event => {
        event.preventDefault();
        const currentValue = Number(inputField.innerText) || 0;
        inputField.innerText = currentValue + 1;
      });
    }

    $( ".property" ).change(function() {
      var postcodeDiv = document.querySelector(".postcode-step");
      if (postcodeDiv) {
        var loader = document.querySelector(".step-loader");
        postcodeDiv.classList.add("tw-opacity-30")
        loader.classList.remove("tw-hidden")
      }
      CI.nextStep(1);
      $('.towncity').val($(this).find("option:selected").data("city"))
      $('.county').val($(this).find("option:selected").data("province"))
      $('.street1').val($(this).find("option:selected").data("street"))
      $('.fullAddress').val($(this).find("option:selected").data("fulladdress"))
      $(".flatNumber").val($(this).find("option:selected").data("flat"));
      $(".uprn").val($(this).find("option:selected").data("uprn"));
      $(".mpan").val($(this).find("option:selected").data("mpan"));
      $('.building').val($(this).find("option:selected").data("building"))
      $(".header-top").removeClass('tw-hidden');
      $(".postcode-show").html($(".postcode").val());
      $(".address-top").removeClass('tw-hidden');
      const selectedValue = $("#property :selected").val().trim()
      $('.temp-property').val(selectedValue)
    });
    
    this.accessToken();

    var next = _.debounce(this.nextStep.bind(this), 100, false);
    var back = _.debounce(this.backStep.bind(this), 100, false);

    $( ".nextStep" ).click(next);
    $( ".backStep" ).click(back);

    $( ".firstStep" ).on('click',function (){
      const pstcodeBtn = document.querySelector(".postcodeBtn");
      const firstStepBtn = document.querySelector(".firstStep");
      if (pstcodeBtn) {
        firstStepBtn.classList.add("tw-hidden")
        pstcodeBtn.classList.remove("tw-hidden")
      }
      $('#dealform').parsley().whenValidate({
        group: 'block-0'
      }).done(() => {
        CI.nextStep(1);
      })
    });

    document.getElementById('focusButton').addEventListener('click', function() {
      var input = document.getElementById('postcode');
      input.scrollIntoView({ behavior: 'smooth', block: 'center' });
      input.focus();
    });
    
    $( ".energy-provider" ).change(function() {
      if ($("#energy-provider :selected").val() !== "" && $("input[name='currently-payment-type']:checked").val() !== "" && $("input[name='currently-payment-type']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });

    $( ".energyprovider" ).change(function() {
      if ($("input[name='energyprovider']:checked").val() !== '' && $("input[name='energyprovider']:checked").val() !== undefined  && $("input[name='currently-payment-type']:checked").val() !== "" && $("input[name='currently-payment-type']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });

    $( ".currently-payment-type" ).change(function() {
      if ($("input[name='currently-payment-type']:checked").val() !== "" && $("#energy-provider :selected").val() !== "" && $("#energy-provider :selected").val() !== undefined || $("input[name='energyprovider']:checked").val() !== '' && $("input[name='energyprovider']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });

    $( ".title" ).change(function() {
      if ($("input[name='title']:checked").val() !== "" && $("input[name='first_name']").val() !== "" && $("input[name='last_name']").val() !== "") {
        CI.nextStep(1);
      }
    });

    $( ".fuel_type" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined && $("#residential-status :selected").val() !== "") {
        CI.nextStep(1);
      }
    });
    $( ".house_type" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined && $("#residential-status :selected").val() !== "") {
        CI.nextStep(1);
      }
    });
    $( ".residential-status" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined && $("#residential-status :selected").val() !== "") {
        CI.nextStep(1);
      }
    });
  }

  fixStepIndicator(num) {
    var progress = document.getElementById("progressBar");
    if (num >= 0) {
      progress.style.width = Math.ceil(num * 20) + "%";
      $(".progress-percent").text(Math.ceil(num * 20) + "%" + " Complete");
      $(".progress-steps").text("Step " + (num + 1) + "/5");
      if (num == 0) {
        $(".progress-percent").text("");
      }
    }
  }

  nextStep() {
    var n = 1;
    var CI = this;
    console.log(CI.currentTab)
    $("#dealform")
      .parsley()
      .whenValidate({
        group: "block-" + CI.currentTab,
      }).done(() => {
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      if (CI.currentTab == 0) {
        var postcodeDiv = document.querySelector(".postcode-step");
        if (postcodeDiv) {
          var loader = document.querySelector(".step-loader");
          postcodeDiv.classList.remove("tw-opacity-30")
          loader.classList.add("tw-hidden")
        }
      }
      CI.currentTab = CI.currentTab + n;
      console.log("next step" + CI.currentTab)
      if (CI.currentTab >= tabs.length) {
        if (CI.isPhone == true) {
          CI.postData();
        } else {
          $("#dealform").parsley().validate();
        }
        return true;
      }
      CI.showTab(CI.currentTab);
      var form = document.getElementById('dealform');
      if (form) {
        window.scrollTo({ top: form.offsetTop, behavior: 'smooth' });
      }
    });
  }

  

  sendSlackNotification(error, spxSessionId){
    $.ajax({
      type: "GET",
      url: "/send_slack_notification",
      data: {message: error, hitMatrixId: spxSessionId},
      success: function(data) {
        console.log(data)
      }
    });
  }

  accessToken() {
    let CI = this;  
    $.ajax({
      type: "GET",
      url: "/get_access_token",
      success: function(data) {
        CI.token = data.token;
      },
      error: function (errorMessage) {
        console.log(errorMessage)
      }
    });
  }
  validateApiPostcode() {
    var CI = this; 
    window.Parsley.addValidator("validapipostcode", {
      validateString: function (value) {
        const pstcodeBtn = document.querySelector(".postcodeBtn");
        const firstStepBtn = document.querySelector(".firstStep");
        if (pstcodeBtn) {
          firstStepBtn.classList.add("in-progress", "tw-opacity-20")
        }
        return $.ajax({
          type: "GET",
          url: `https://api.rebelenergy.com/api/v2/addresses/${$(
            ".postcode"
          ).val()}`,
          headers: {
            'Authorization': `Bearer ${CI.token}`
          },
          success: function (json) {
            $(".property-div").show();
            if (json.length > 0) {
              var result = json;
              var adresses = [];
              adresses.push(`
                <option
                disabled=""
                selected="selected"
                >
                Select Your Property
                </option>
              `);
              for (var i = 0; i < result.length; i++) {
                adresses.push(`
                    <option
                    data-street="${result[i]?.street}"
                    data-city="${result[i]?.posttown}"
                    data-fullAddress="${result[i]?.fullAddress}"
                    data-building="${result[i]?.number}"
                    data-uprn="${result[i]?.uprn}"
                    data-mpan="${result[i]?.mpan}"
                    data-flat="${result[i]?.flat}">
                      ${result[i].fullAddress}
                    </option>
                  `);
              }
              $("#property").html(adresses);
              if ($('.temp-property').val()) {
                $('#property').val($('.temp-property').val())
              }
              if (pstcodeBtn) {
                pstcodeBtn.classList.remove("tw-hidden");
                firstStepBtn.classList.add("tw-hidden");
                firstStepBtn.classList.remove("in-progress");
              }
              $(".property-dropdown").show();
              $(".address-div").remove();
              return true;
            } else {
              return $.Deferred().reject("Please Enter Valid Postcode");
            }
          },
          error: function (request) {
            console.log(request.statusText);
            request.abort();
            if (request.statusText == "timeout") {
              var spxSessionId = localStorage.getItem('spxSessionId');
              var hitMatrixURL = `https://cp-inst47-client.phonexa.uk/p8/hitmetrix/recordings/play?sessionId=${spxSessionId}`
              CI.sendSlackNotification(request, "Address LookUp bill-switchers Home Hit-Matrix-Id: " + hitMatrixURL)
              $(".property-div").remove();
              $(".address-div").show();
              $("#postcode").removeAttr('data-parsley-required');
              $("#postcode").removeAttr('required');
              $(".postcode").removeClass('error');
              $(".postcode").addClass('valid');
              $(".postcode-error").remove();
              $(".parsley-error-list").remove();
              $(".property-dropdown").show();
              CI.apiDown = true;
            }
          },
          timeout: 10000,
        });
      },
      messages: {
        en: "Please Enter Valid Postcode",
      },
    });
  }

  getData() {
    var spxSessionId = localStorage.getItem('spxSessionId');
    if (spxSessionId) {
      $(".spxSessionId").val(spxSessionId)
    }
    var urlWithParams = window.location.href;
    var bedroom_numbers = document.getElementById('total-bedrooms');
    if (bedroom_numbers) {
      bedroom_numbers = bedroom_numbers.innerText
    }
    if (bedroom_numbers <= 0) {
      bedroom_numbers = $("input[name='bedroom_count']:checked").val()
    }
    return {
      urlWithParams: urlWithParams,
      spxSessionId: spxSessionId || $(".spxSessionId").val() || "",
      postcode: $(".postcode").val() || this.getUrlParameter("postcode") || "",
      firstname:
        $(".first_name").val() || this.getUrlParameter("firstname") || "",
      lastname: $(".last_name").val() || this.getUrlParameter("lastname") || "",
      email: $(".email").val() || this.getUrlParameter("email") || "",
      phoneNumber: $(".phone").val() || this.getUrlParameter("phone1") || "",
      street1: $(".street1").val() || this.getUrlParameter("street1") || "",
      building: $(".building").val() || this.getUrlParameter("building") || "",
      title:
        $("input[name='title']:checked").val() ||
        this.getUrlParameter("title") ||
        "Mr",
      lead_id: this.getUrlParameter("lead_id") || "unknown",
      sid: this.getUrlParameter("sid") || 1,
      source:
        this.getUrlParameter("source") ||
        this.details.source ||
        "Google-Mobile",
      keyword: this.getUrlParameter("keyword") || "",
      ssid: this.getUrlParameter("ssid") || this.details.ssid || "unknown",
      paymentmethod:
        $("input[name='currently-payment-type']:checked").val() ||
        this.getUrlParameter("paymentmethod") ||
        "",
      currentprovider:
        $("#energy-provider option:selected").val() ||
        $("input[name='energyprovider']:checked").val() ||
        this.getUrlParameter("currentprovider") ||
        "",
        residentialStatus:
        $("#residential-status option:selected").val() ||
        $("input[name='residential-status']:checked").val() ||
        this.getUrlParameter("residentialStatus") ||
        "",
      prize: this.getUrlParameter("prize") || "2",
      trafficid: this.getUrlParameter("trafficid") || "Bill-Switchers",
      towncity: $(".towncity").val() || this.getUrlParameter("towncity") || "",
      optindate: this.getFormattedCurrentDate(),
      optinurl: this.details.optinUrl || "http://bill-switchers.com/p1",
      ipaddress: this.details.ipaddress,
      apiDown: this.apiDown,
      url_with_params: window.location.href,
      uu_id: this.details.uu_id || "",
      uprn: $(".uprn").val() || "",
      mpan: $(".mpan").val() || "",
      lead_from_local_storage: this.userStorage,
      campaign: this.getUrlParameter("campaign") || "",
      adopted_url: this.adoptedUrl,
      clickid: this.getUrlParameter('gclid') || "",
      adclid: this.getUrlParameter('adclid') || "",
      gclid: this.getUrlParameter("gclid") || $(".gclid").val() || "",
      keyword: this.getUrlParameter("keyword") || $(".keyword").val() || "",
      ad_set: this.getUrlParameter("ad_set") || $(".ad_set").val() || "",
      mkwid: this.getUrlParameter("mkwid") || $(".mkwid").val() || "",
      monthly_bill: $("#monthly_bill").val(),
      fuelType: $("input[name='fuel_type']:checked").val(),
      buildingType: $("input[name='house_type']:checked").val(),
      bedrooms : bedroom_numbers || "",
      county: $(".county").val() || this.getUrlParameter('county') || "",
      matchType: this.getUrlParameter("matchType") || $(".matchType").val() || "",
      adGroupId: this.getUrlParameter("adGroupId") || $(".adGroupId").val() || "",
      fullAddress: this.getUrlParameter("fullAddress") || $(".fullAddress").val() || "",
      flatNumber: this.getUrlParameter("flatNumber") || $(".flatNumber").val() || "",
      aff_id: this.getUrlParameter("aff_id") || $(".aff_id").val() || "",
      ttclid: this.getUrlParameter("ttclid") || "",
    };
  }


  postData() {
    var CI = this;
    this.firePixel();
    $('#new_page').addClass('d-none');
    $('.footer_page').addClass('d-none');
    $('#loading-page').removeClass('d-none');
    $('#loader-content').html($(".postcode").val());
    $(".tab").removeClass("in-progress");
    console.log("Postdata: " + new Date());
    CI.successUrl()
  }

  successUrl(){
    var CI = this
    $.ajax({
      type: "POST",
      url: "/submit_lead_affid",
      data: this.getData(),
      success: function(data) {
        if(data.data.redirect_url != "" &&  data.data.redirect_url != undefined && data.data.redirect_url != null) {
          window.location.href = data.data.redirect_url
        }else if(data.data.rejectUrl != ""  &&  data.data.rejectUrl != undefined && data.data.rejectUrl != null){
          window.location.href = data.data.rejectUrl+'?'+jQuery.param(CI.getData())
          console.log(data)
        }else {
          window.location.href = "/duplicate"
        }
      },
      error: function (textStatus, errorMessage) {
        console.log(textStatus)
        console.log(errorMessage)
      },
      dataType: "json"
    })
  }
}

export default new Form();
